import React from "react";
import ReactMarkdown from "react-markdown";
import Button from "../Button/Button";

import Slide from "react-reveal/Slide";

import FavoriteIcon from "@material-ui/icons/Favorite";

import LayoutComponent from "../LayoutComponent/LayoutComponent";

import "./firstComponentAFR.scss";
import rehypeRaw from "rehype-raw";

const FirstComponentAFR = ({
  animation,
  primary_color,
  secondary_color,
  background_color,
  card_color,
  title,
  description,
  price,
  image,
  cards,
  hover,
  button_color,
  button_icon,
  button_text,
  onClickUrl,
}) => {
  return (
    <div className="firstComponentAFR-wrapper">
      <LayoutComponent
        background={
          primary_color && secondary_color
            ? `radial-gradient(50% 50% at 50% 50%, ${secondary_color} 0%, ${primary_color} 92.19%)`
            : background_color
        }
      >
        <div className="body-content">
          <section>
            {title && (
              <h1 className="title">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={title}
                />
              </h1>
            )}
            {description && (
              <div className="description">
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  children={description}
                />
              </div>
            )}
            {price && (
              <div
                className="price"
                style={{ color: card_color ? card_color : "" }}
              >
                {price}
              </div>
            )}
            {/* <a>
              <Button
                to={onClickUrl}
                variant={"outlined"}
                backgroundColor={"transparent"}
                hover={hover}
                color={button_color ? button_color : "white"}
                text={button_text}
                icon={button_icon ? <FavoriteIcon /> : null}
                icon_color={"#868BFC"}
              />
            </a> */}
          </section>
          {animation ? (
            <Slide right cascade>
              <section className="image-wrapper">
                <img src={image} alt={"CMO AFR QR kódos fizetés logo"} loading="lazy"/>
              </section>
            </Slide>
          ) : (
            <section className="image-wrapper">
              <img src={image} alt={"CMO AFR QR kódos fizetés logo"} loading="lazy"/>
            </section>
          )}
        </div>
      </LayoutComponent>
      {cards && (
        <div className="cards">
          {cards.map((card, index) => {
            return (
              <div
                className="card"
                key={index}
                style={{ background: card_color ? card_color : {} }}
              >
                {card.description}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default FirstComponentAFR;
