import React from "react";
import ReactMarkdown from "react-markdown";
import Button from "../../components/Button/Button";
import PaymentMethods from "../../components/PaymentMethods/PaymentMethods";
import FirstComponentAFR from "../../components/FirstComponentAFR/FirstComponentAFR";
import LayoutComponent from "../../components/LayoutComponent/LayoutComponent";
import LayoutHeaderFooter from "../../components/LayoutHeaderFooter/LayoutHeaderFooter";

import "./productPages.scss";
import rehypeRaw from "rehype-raw";

const ProductPages = (props) => {
  const {
    primary_color,
    secondary_color,
    card_color,
    title,
    description,
    price,
    prodDescCard,
    image,
    content,
    paymentMethodsAvailable,
    content_2,
    button_text,
    button_url,
    SEO,
  } = props.pageContext.content;

  const { paymentMethods } = props.pageContext;

  return (
    <div className="productPages-wrapper">
      <LayoutHeaderFooter
        header_background_color={primary_color}
        header_secondary_color={secondary_color}
        SEO_title={SEO.title}
        SEO_description={SEO.description}
        SEO_keywords={SEO.keywords}
      >
        <FirstComponentAFR
          primary_color={primary_color}
          secondary_color={secondary_color}
          title={title}
          description={description}
          cards={prodDescCard}
          card_color={card_color}
          animation
          image={`${process.env.GATSBY_CMS_URL}${image?.url}`}
          price={price}
          hover={"hover_white_purple"}
          onClickUrl={button_url}
        />
        <LayoutComponent>
          {content && (
            <div
              className="content"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={content}
              />
            </div>
          )}
        </LayoutComponent>
        {paymentMethodsAvailable && (
          <PaymentMethods
            title={
              "Fizetési lehetőségek, amivel a legtöbb CMO rendszer kompatibilis"
            }
            content={paymentMethods}
          />
        )}
        <LayoutComponent>
          {content_2 && (
            <div
              className="content"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <ReactMarkdown
                rehypePlugins={[rehypeRaw]}
                children={content_2}
              />
            </div>
          )}
        </LayoutComponent>
      </LayoutHeaderFooter>
    </div>
  );
};

export default ProductPages;
